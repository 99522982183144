<template>
    <div class="respond-to-approval">
        <div class="logo"></div>
        <h2>Approval Requested</h2>
        <div class="text-center mt-4"  v-if="loading">
            <b-spinner></b-spinner>
        </div>

        <div class="approval-information" v-if="!completed && approval">
            <b-row>
                <b-col cols="12">
                    <EntryPreview :type="approval.type" :attributes="approval.attributes"/>
                </b-col>
                <b-col cols="12">
                    <b-button variant="outline-primary" size="sm" @click="showLinkedEntry" v-if="approval.linkedEntryAttributes"><FileLinkOutlineIcon /> View Linked Reflection</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <ApprovalResponse @approve="approve" @reject="reject" />
                </b-col>
            </b-row>
        </div>

        <ApprovalCompleted v-if="completed" />

        <b-modal ref="linkedModal" size="lg" title="Preview - Linked Entry" header-bg-variant="primary" header-text-variant="light" hide-footer>
            <EntryPreview v-if="approval && approval.linkedEntryAttributes" :attributes="approval.linkedEntryAttributes" type="" />
        </b-modal>

        <Footer :version="version" />
    </div>
</template>

<script>
    import ApprovalCompleted from "../../components/external/approval/ApprovalCompleted";
    import EntryPreview from "../../components/external/approval/EntryPreview";
    import ApprovalResponse from "../../components/external/approval/ApprovalResponse";
    import FileLinkOutlineIcon from 'vue-material-design-icons/FileLinkOutline';

    import Providers from '../../providers';
    import Footer from 'cpdcloud-dashboard/src/components/common/Footer';
    import {version} from '../../../package';

    export default {
        name: "RespondToApproval",
        components: {ApprovalResponse, EntryPreview, ApprovalCompleted, Footer, FileLinkOutlineIcon},
        data() {
            return {
                loading: false,
                accessKey: '',
                type: '',
                completed: false,
                approval: null,
                version: version
            }
        },
        methods: {
            loadApproval() {
                this.loading = true;
                Providers.external.approvals.approvalPreview(this.type, this.accessKey).then((resp) => {
                    this.approval = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load approval', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            showLinkedEntry() {
                this.$refs.linkedModal.show();
            },
            approve(comments) {
                Providers.external.approvals.approve(this.type, this.accessKey, comments).then(() => {
                    this.completed = true;
                }).catch((resp) => {
                    this.$httpError('Failed to approve entry', resp);
                });
            },
            reject(comments) {
                Providers.external.approvals.reject(this.type, this.accessKey, comments).then(() => {
                    this.completed = true;
                }).catch((resp) => {
                    this.$httpError('Failed to return entry', resp);
                });
            }
        },
        created() {
            this.accessKey = this.$route.params.accessKey;
            this.type = this.$route.params.type;
            this.loadApproval();
        }
    }
</script>

<style scoped>

    h2 {
        text-align: center;
        margin-top: 10px;
    }

    div.logo {
        background-image: url('/images/logo.png');
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }

    div.respond-to-approval {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 80px;
    }

</style>