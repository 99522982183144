<template>
    <div class="approval-completed">
        <b-alert show title="Test2" variant="success" class="text-center mt-4">
            <b>Thank You</b><br />
            Your response has been sent to the sender. You can now close this window to end your session.
        </b-alert>
    </div>
</template>

<script>
    export default {
        name: "ApprovalCompleted"
    }
</script>

<style scoped>

</style>